<template>
<div class="sem-wrapper">
  <NewHeader :text-list="list" width="50vw">
    <img class="background" src="./assets/banner.png" alt="background">
  </NewHeader>
  <div class="content">
    <h1 class="title">{{$t('nav.second.menu4')}}</h1>
    <div class="sub-title">
      <span class="sub-title-item">{{$t('semiconductor.subtitle')}}</span>
      <div class="c-desc">{{$t('semiconductor.desc')}}</div>
    </div>

    <div style="margin-top: 60px" class="sub-title">
      <span class="sub-title-item">{{$t('semiconductor.app')}}</span>
      <div class="image-items">
        <div v-for="(it, index) in images"
             class="image"
             :key="index">
          <img
               :src="it.src" alt="">
          <div class="item-desc">{{$t(it.name)}}</div>
        </div>
      </div>
    </div>
  </div>
  <ContactUs/>
</div>
</template>

<script>
import ContactUs from '@/components/ContactUs'
import NewHeader from '@/components/NewHeader'
export default {
  name: 'Semiconductor',
  components: { NewHeader, ContactUs },
  data () {
    return {
      images: [{
        src: require('./assets/1.png'),
        name: 'semiconductor.prod1'
      }, {
        src: require('./assets/2.png'),
        name: 'semiconductor.prod2'
      }, {
        src: require('./assets/3.png'),
        name: 'semiconductor.prod3'
      }],
      list: [
        'Semiconductor',
        'Hallonburg industry is a good partner for your enabling innovations in semiconductor processing.'
      ]
    }
  }
}
</script>

<style lang="stylus">
@import "../../assets/styles/global.styl"
.image-items
  margin-top 15px
  display flex-wrap
  flex-direction row
  .item-desc
    font-size $small
    color: $blue
  @media screen and (max-width: 600px)
    .image
      img
        width 100%
  .image
    display inline-block
    margin-right 1.5vw
.c-desc
  font-size: $small
  width 90%
  font-family: "Myriad Pro"
  color: $text_color
  line-height: 1.875
  text-align: left
  margin-top 10px
.sub-title
  font-family 'SourceSansPro'
  font-size $middle
  line-height 1.5
  .sub-title-text
    font-family 'SourceSansPro'
    font-size $middle
    text-align center
    line-height 1.5
  .second-sub-title
    centers()
    @media screen and (max-width: 600px)
      min-width: 300px
      font-size 14px
    .sub-title-item
      white-space pre-wrap
.content
  position relative
  flex-direction column
  padding 0 15vw
  padding-bottom: 80px
  .title
    position relative
    font-size: $large
    font-family: "SourceSansPro"
    font-weight: bold
    line-height: 1.2
    text-align: left
    margin-top 80px
    margin-bottom 50px
    &::before
      content: ""
      position: absolute
      width 4vw
      left 2vw
      margin-top: 50px
      transform: translateX(-50%)
      border-bottom: 3px solid $blue
.sem-wrapper
  .title-wrapper
    width 80vw !important
</style>
